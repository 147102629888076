.wrapper {
    height: 68px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
}
.button {
    height: 38px;
    padding: 6px 18px 6px 18px;
    border: 2px solid #1656ff;
    // border-image: linear-gradient(105.83deg, #487bff 16.86%, #1656ff 61.05%);
    border-image-slice: 1;
    border-radius: 8px;
    background: none;
    color: #f7f8fa;
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
        background: linear-gradient(105.83deg, #487bff 16.86%, #1656ff 61.05%);
    }
}

.disabled {
    color: #575a60;
    border-color: #575a60;
    pointer-events: none;
}

.logo {
    height: 42px;
    margin-right: auto;
}

.testing {
    margin-right: 40px;
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #575a60;
    display: flex;
    gap: 12px;
}

.loader {
    width: 24px;
    height: 24px;
    padding: 2px;
    border-radius: 50%;
    background: #575a60;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}
