.wrapper {
    min-height: 100vh;
    max-width: 100vw;
    padding: 0px 94px 60px 94px;
}

.title {
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #edeff4;
    padding: 16px 0px 48px 0px;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 34px;
}

.block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding: 0px 16px 0px 16px;
    }
}
