.wrapper {
    width: 400px;
    height: 400px;
    border-radius: 24px;
    background: #222837;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 34px 20px;
    gap: 28px;
    position: relative;
    // justify-content: space-between;
}

.title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #edeff4;
    margin-bottom: 20px;
}

.amount {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #edeff4;
    margin-top: 20px;
    transition: 'transform 0.3s linear';
}

.description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #8c93b2;
}

.barWrapper {
    width: 100%;
    height: 228px;
    color: #3360d11f;
}
