.wrapper {
    width: 400px;
    height: 400px;
    border-radius: 24px;
    background: #222837;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 34px;
    gap: 24px;
    position: relative;
    justify-content: space-between;
}

.title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #edeff4;
}

.amount {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #edeff4;

    transition: 'transform 0.3s linear';
}

.description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #8c93b2;
}

.block {
    display: flex;
    flex-direction: column;
}

.button {
    cursor: pointer;
    width: 280px;
    height: 44px;
    padding: 10px 24px 10px 24px;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid #8c93b2;
    background: none;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #8c93b2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled {
    pointer-events: none;
    opacity: 0.3;
}
