body {
    margin: 0;
    font-family: 'Inter', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    background: linear-gradient(180deg, #12161e 0%, #070d19 100%);
}

:root {
    /* vars */
    --header-height: 96px;
    --container-padding: 20px;
    --animation-transition-speed: 0.2s;
    --page-top-padding: 60px;

    /* z-index`s */
    --z-index-header-nav-menu: 10;
    --z-index-header-langs: 9;
    --z-index-mobile-menu: 8;
    --z-index-softnote-tab: 7;

    /* color vars */
    --primary-color: #2261f6;

    --background: #12161e;
    --font-base-color: #fff;
}

/* Указываем box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}
